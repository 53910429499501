
<template> 
<TABLE WIDTH=100% BORDER=0 CELLPADDING=0 CELLSPACING=0>
	<TR>
		<TD COLSPAN=2>
			<img SRC="/images/header/back1_01.gif" WIDTH=100% HEIGHT=100% ALT=""></TD>
		<TD ROWSPAN=3>
			<img SRC="/images/header/back1_02.gif" WIDTH=100% HEIGHT=100% ALT=""></TD>
		<TD ROWSPAN=3>
			<img SRC="/images/header/back1_03.gif" WIDTH=100% HEIGHT=100% ALT=""></TD>
		<TD ROWSPAN=3>
			<img SRC="/images/header/back1_04.gif" WIDTH=100% HEIGHT=100% ALT=""></TD>
		<TD ROWSPAN=3>
			<img SRC="/images/header/back1_05.gif" WIDTH=100% HEIGHT=100% ALT="">
            <div class="top-right">
                <b-input-group size="sm" style="margin-top: 3%;">
                <!-- <b-form-input id="searchbar_background" style="width:100px; background-color: #fae33b; text-decoration: black; " placeholder="Search"></b-form-input> -->
                <b-input-group-append>
                  <!-- <span id="searchbar_background" style="background-color: #fae33b; vertical-align: middle;" > -->
                    <!-- <b-icon icon="search" style="cursor: pointer; width: 25px; "></b-icon></span> -->
                  <a id="login_background" :href="backendUrl()" style="width:80px; height: 32px; text-align:center; background-color: #fae33b;" target="_blank">Login</a>
                </b-input-group-append>
              </b-input-group>
            </div>
        </TD>
	</TR>
	<TR>
		<TD>
			<img SRC="/images/header/back1_06.gif" WIDTH=100% HEIGHT=101 ALT=""></TD>
		<TD>
			<router-link :to="{name:'home'}"><img SRC="/images/header/back1_07.gif" WIDTH=100% HEIGHT=100% ALT=""></router-link></TD>
	</TR>
	<TR>
		<TD COLSPAN=2>
			<img SRC="/images/header/back1_08.gif" WIDTH=100% HEIGHT=100% ALT=""></TD>
	</TR>
</TABLE>
</template>

<script>
  export default {

       methods: {
    handleScroll () {

    },
    backendUrl(){
      //window.location.href=process.env.VUE_APP_ENV_BACKENDURL;
      return process.env.VUE_APP_ENV_BACKENDURL;
    }
  },
  };
</script>

<style>
/* Top right text */
.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}
</style>